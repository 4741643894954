(function() {
    window.apos = window.apos || {};
    var data = document.body && document.body.getAttribute('data-apos');
    Object.assign(window.apos, JSON.parse(data || '{}'));
    if (data) {
      document.body.removeAttribute('data-apos');
    }
    if (window.apos.modules) {
      for (const module of Object.values(window.apos.modules)) {
        if (module.alias) {
          window.apos[module.alias] = module;
        }
      }
    }
})();import index_0App from "/var/www/gymjazz/node_modules/apostrophe/modules/@apostrophecms/util/ui/src/index.js";


import index_1App from "/var/www/gymjazz/node_modules/apostrophe/modules/@apostrophecms/admin-bar/ui/src/index.js";


import index_2App from "/var/www/gymjazz/node_modules/apostrophe/modules/@apostrophecms/video-widget/ui/src/index.js";


import index_3App from "/var/www/gymjazz/modules/map-widget/ui/src/index.js";


import index_4App from "/var/www/gymjazz/modules/contact-widget/ui/src/index.js";



import index_0Stylesheet from "/var/www/gymjazz/modules/@apostrophecms/home-page/ui/src/index.scss";


import index_1Stylesheet from "/var/www/gymjazz/node_modules/apostrophe/modules/@apostrophecms/image-widget/ui/src/index.scss";


import index_2Stylesheet from "/var/www/gymjazz/modules/asset/ui/src/index.scss";


import index_3Stylesheet from "/var/www/gymjazz/modules/default-page/ui/src/index.scss";


import index_4Stylesheet from "/var/www/gymjazz/modules/list-widget/ui/src/index.scss";


import index_5Stylesheet from "/var/www/gymjazz/modules/text-widget/ui/src/index.scss";


import index_6Stylesheet from "/var/www/gymjazz/modules/map-widget/ui/src/index.scss";


import index_7Stylesheet from "/var/www/gymjazz/modules/block-widget/ui/src/index.scss";


import index_8Stylesheet from "/var/www/gymjazz/modules/contact-widget/ui/src/index.scss";index_0App();
index_1App();
index_2App();
index_3App();
index_4App();